<template>
  <section>
    <title-section
      title="Marcas de productos"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalAddBrandParent = true">
          AGREGAR MARCA
        </button>
      </div>
    </title-section>
    <brand-main
      :activeModalAddBrand="activeModalAddBrandParent"
      @close-modal="activeModalAddBrandParent = false"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Brand',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    BrandMain: () => import('@/components/Products/Brand/BrandMain.vue')
  },
  data () {
    return {
      activeModalAddBrandParent: false
    }
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
